import React, { useState, useRef, useImperativeHandle, forwardRef, useEffect } from 'react'
import './styles/institucional.scss'
import './styles/lojas.scss'
import { Helmet } from 'react-helmet'
import Layout from '../components/Layout/index'
import { graphql, Link } from 'gatsby'
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image'

// assets
import iconSearch from '../images/lojas/search.svg'
import buttonDelivery from '../images/lojas/buttonDelivery.svg'

const Banner = ({ bannerDelivery, bannerDeliveryMobile }) => {
  const sources = withArtDirection(getImage(bannerDelivery), [
    {
      media: '(max-width: 768px)',
      image: getImage(bannerDeliveryMobile)
    }
  ])

  return <GatsbyImage image={sources} alt="Banner Delivery" className="customBannerDelivery" />;
}

const Pagination = forwardRef((props, ref) => {
  const [pages, setPages] = useState([1, 2, 3])

  useImperativeHandle(ref, () => ({
    goToFirstPage () {
      setPages([1, 2, 3])
    }
  }))

  const showMore = () => {
    if (pages[2] < Math.round(props.markets.length / 6)) {
      const newPages = pages.map(page => page + 1)
      setPages(newPages)
    }
  }

  const showLess = () => {
    if (pages[0] > 1) {
      const newPages = pages.map(page => page - 1)
      setPages(newPages)
    }
  }

  const next = () => {
    const page = props.pagination.active
    props.pagination.setActive(page + 6)
    if (page >= 12) {
      showMore()
    }
  }

  const prev = () => {
    props.pagination.setActive(props.pagination.active - 6)
    if (props.pagination.active >= 6) {
      showLess()
    }
  }

  const handleClick = (currentPagination, currentPage) => {
    props.pagination.setActive(currentPagination)
    if (pages.indexOf(currentPage) === 2) {
      showMore()
    } else if (pages.indexOf(currentPage) === 0) {
      showLess()
    }
  }
  return (
    <>
      <div className="d-flex justify-content-center py-3">
        <button className="bg-transparent border-0 px-4" disabled={pages[0] === 1 && props.pagination.active === 0}
          onClick={() => prev()}>
          <i className={pages[0] === 1 && props.pagination.active === 0 ? 'arrow-gray arrow-left' : 'arrow-black arrow-left'}></i>
        </button>
        <div className="row justify-content-center lojas-paginador">
          {
            pages.map(page => {
              const currentPagination = (page - 1) * 6
              return (
                <div className="col d-flex justify-content-center" key={page}>
                  <span key={page}><button onClick={() => handleClick(currentPagination, page)}
                    className={(currentPagination === props.pagination.active)
                      ? 'rounded px-3 py-2 text-white btn-water-green border-0' : 'px-3 py-2 border-0 bg-transparent'}
                  >{page}</button></span>
                </div>
              )
            })
          }
        </div>
        <button className="bg-transparent border-0 px-4" disabled={pages[2] >= Math.round(props.markets.length / 6)} onClick={() => next()}>
          <i className={pages[2] >= Math.round(props.markets.length / 6) ? 'arrow-gray arrow-right' : 'arrow-black arrow-right'}></i>
        </button>
      </div>
    </>
  )
})

Pagination.displayName = 'Pagination'

const LojasDelivery = ({ data }) => {
  const breadC = {
    visible: true,
    color: 'dark',
    items: [
      { path: '/', name: 'Home' },
      // { path: '/institucional/', name: 'Institucional' },
      { path: '/delivery/', name: 'Lojas Delivery' }
    ]
  }
  const lojas = []
  data.lojas.nodes.map(loja =>
    loja.services.map(serv => {
      if (serv.name === 'Express' || serv.name === 'Ifood' || serv.name === 'Rappi') {
        lojas.push(loja)
      }
    })
  )
  const [markets, setMarkets] = useState(lojas)
  const paginationRef = useRef()
  const [searchName, setSearchName] = useState('')
  const [activePage, setActivePage] = useState(0)
  const [currentMarket, setCurrentMarket] = useState(null)
  const [searchResult, setSearchResult] = useState('')
  const childRef = useRef()

  useEffect(() => {
    initMaps()
    useCurrentLocation()
  }, [])

  const initMaps = () => {
    if (typeof window !== 'undefined') {
      if (!window.google) {
        const script = document.createElement('script')
        script.src = `https://maps.googleapis.com/maps/api/js?language=pt-BR&libraries=places&key=${process.env.GATSBY_CREDENTIAL_API_MAPS_LOJAS}`
        const insercao = document.getElementsByTagName('script')[0]
        insercao.parentNode.insertBefore(script, insercao)
        script.addEventListener('load', e => {
          console.log('Geocoder is now available!')
        })

        if (window.localStorage && currentMarket === null) {
          const temp = window.localStorage.getItem('currentMarket')
          setCurrentMarket(JSON.parse(temp))
        }
      }
    }
  }

  const useCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        }
        calcDistance(pos)
      })
    } else {
      console.error('Geolocation is not supported by this browser.')
    }
  }

  const handleSearch = (e) => {
    e.preventDefault()
    const geocoder = new window.google.maps.Geocoder()
    geocoder.geocode({
      address: searchResult,
      region: 'br',
      componentRestrictions: {
        country: 'BR'
      }
    },
    (results, status) => {
      console.log('google results', { results: results, status: status })

      if (status === 'OK') {
        const pos = {
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng()
        }
        calcDistance(pos)
        if (paginationRef.current) {
          paginationRef.current.goToFirstPage()
        }

        setSearchName(`Resultados de Busca para "${results[0].formatted_address}"`)
        // const searchLabel = [...new Set(results[0].address_components.map(res => res.long_name))].reduce((a, b) => a + ', ' + b)
      } else {
        console.log('FAIL')
      }
    })
  }

  const calcDistance = (p) => {
    console.log('>> CalcDistance')
    const myLat = p.lat
    const myLng = p.lng
    // eslint-disable-next-line no-unused-vars
    let mkts = lojas.map(node => {
      const LatLoja = node.lat
      const LngLoja = node.lng
      const radlat1 = (Math.PI * myLat) / 180
      const radlat2 = (Math.PI * LatLoja) / 180

      const theta = myLng - LngLoja
      const radtheta = (Math.PI * theta) / 180
      let dist =
        Math.sin(radlat1) * Math.sin(radlat2) +
        Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta)
      dist = Math.acos(dist)
      dist = (dist * 180) / Math.PI
      dist = dist * 60 * 1.1515
      dist = dist * 1.609344
      node.distance = parseFloat(dist.toFixed(1))
      return node
    })

    mkts = mkts.sort((a, b) => (a.distance - b.distance))
    setMarkets(mkts)
    console.log('mkts ->', mkts)
    setActivePage(0)
    if (childRef.current) {
      childRef.current.currentMarket(mkts[0])
    }
  }

  const handleMarketSelected = (market) => {
    if (typeof window !== 'undefined') {
      window.localStorage.setItem('currentMarket', JSON.stringify(market))
      document.getElementById('currentMarket').innerHTML = market.name
    }
  }

  return (
    <Layout breadCrumb={breadC} >
      <Helmet>
        <html lang="pt-BR" amp />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
        <title>Delivery - Dia Supermercados</title>
        <meta name="description" content="Conheça nosso Delivery" />
        <meta name="author" content="DIA Supermercados" />
      </Helmet>
      <Banner bannerDelivery={data.bannerDelivery.childImageSharp.gatsbyImageData} bannerDeliveryMobile={data.bannerDeliveryMobile.childImageSharp.gatsbyImageData} />
      <div className="container">
        <div className="row justify-content-between my-4">
          <div className="col-12">
            <h1>Lojas Delivery</h1>
            <h3>Mais uma forma pra gente ajudar você a se cuidar.</h3>
          </div>
          <div className="col-9">
            <form onSubmit={(e) => handleSearch(e)} className="d-flex mt-lg-0 mt-4 mb-lg-3 mb-0">
              <label htmlFor="input-search"></label>
              <input type="text" name="input-search" id="input-search" className="inputLoja border-0 shadow-delivery-input pt-2 pb-2 pr-5 pl-3 font-size-1" onChange={(e) => setSearchResult(e.target.value)} value={searchResult} style={{ outline: 'none' }} placeholder="Digite seu Endereço/CEP" />
              <button type="submit" name="button" className="position-absolute border-0 bg-transparent outline-0" aria-label="search-button" style={{ right: 0 }}>
                <img src={iconSearch} className="imgLupa position-absolute" alt="search icon" />
              </button>
              {/* <input type="text" className={`${styles.inputBusca} w-100 rounded-left`} placeholder="Digite seu Endereço/CEP" onChange={(e) => setSearchResult(e.target.value)} value={searchResult} style={{ outline: 'none' }}/>
                <button className="bg-white rounded-right border-0 d-lg-block d-none" style={{ outline: 'none' }}>
                  <img src={iconSearch} className="pr-3" />
                </button> */}
            </form>
          </div>
          <div className="col-2 d-flex justify-content-end">
            <img src={buttonDelivery} alt="button delivery" width="108" height='48' />
          </div>
          {searchResult !== '' &&
            <div className="col-lg-12 my-2">
              <h2 className="text-delivery">{searchName}</h2>
            </div>
          }
        </div>
      </div>
      <div className="container">
        <div className="row">
          {
            markets.slice(activePage, activePage + 6).map((loja, index) => {
              return (
                <div key={index} className="col-lg-6 col-12 mb-5">
                  <div className="card card-lojas border-0">
                    <div className="row">
                      <div className="col-lg-7">
                        <h2 className="text-red font-size-15 mt-0 mb-3">{loja.name}</h2>
                        <p className="font-size-1" style={{ lineHeight: '24px' }}>
                          {loja.address}<br/>
                          {loja.district} - {loja.uf} <br/>
                              CEP {loja.cep} <br/>
                          {loja.distance && <>Distância: {loja.distance} Km</>}
                        </p>
                        <p className="mt-2 font-size-09 text-gray-1">
                              Segunda a Sábado: {loja.mondayOpen.substr(0, 5)} às {loja.mondayClose.substr(0, 5)}<br/>
                              Domingo: {loja.sundayOpen.substr(0, 5)} às {loja.sundayClose.substr(0, 5)}<br/>
                              Feriados: {loja.holidayOpen.substr(0, 5)} às {loja.holidayClose.substr(0, 5)}</p>
                      </div>
                      <div className="col-lg-5 w-100">
                        <div className="row pl-3">
                          {loja.services.length > 0 && <p className="text-gray-1 d-flex align-items-center font-Yanone font-size-12">Serviços</p>}
                          {loja.services.map(servico => {
                            return (
                              <img
                                width="30" height="30"
                                src={servico.icon}
                                alt={`${servico.name} disponível na loja.`}
                                className='ml-1'
                                key={servico.name}
                                data-toogle="tooltip" title={servico.name} />
                            )
                          })}
                          {loja.services.length === 0 && <span>&nbsp;</span>}
                        </div>
                        <a href={`https://www.google.com/maps/dir/Minha+Localização/${loja.lat},${loja.lng}`}><button type="button" className="mt-4 mb-3 btn btn-water-green-2 w-100 font-Yanone font-size-1">Como chegar</button></a>
                        <Link to={`/lojas/${loja.slug}`} className="mb-3 btn btn-red-1 w-100 font-Yanone p-2 font-size-1" onClick={() => handleMarketSelected(loja)}>Ofertas da semana</Link>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
        <Pagination markets={markets} pagination={{ active: activePage, setActive: setActivePage }} ref={paginationRef} />
      </div>
    </Layout>
  );
}
export default LojasDelivery

export const query = graphql`{
  lojas: allCloudOfertasLoja {
    nodes {
      id
      slug
      address
      cep
      city
      district
      lat
      lng
      name
      reference
      status
      uf
      whatsapp
      mondayOpen
      mondayClose
      holidayOpen
      holidayClose
      sundayOpen
      sundayClose
      tariff {
        id
        name
      }
      services {
        icon
        name
      }
    }
  }
  bannerDelivery: file(
    relativePath: {eq: "delivery/banner-dia-delivery-desktop.png"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  bannerDeliveryMobile: file(
    relativePath: {eq: "delivery/banner-mob-dia-delivery.png"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 340, height: 270, layout: CONSTRAINED)
    }
  }
}
`
